import React, { lazy, useEffect, useState } from 'react'

import 'modern-normalize/modern-normalize.css'
import 'prismjs/themes/prism.css'
import 'scss/gatstrap.scss'
import 'animate.css/animate.css'
import { Link } from 'gatsby'
import SimpleReactLightbox from 'simple-react-lightbox'
import Sticky from 'react-sticky-el'
import _ from 'lodash'
import { Headers } from '../../sites-config/global'
import { AsideMenus } from '../../sites-config/global'
interface Props {
  children?: React.ReactNode
  location: Location
  edition: string
}

const defaultProps = {
  edition: '01-2021',
}
const Layout: React.FC<Props> = ({ children, location, edition }: Props) => {
  const AsideMenu = _.get(AsideMenus, edition.replace('-', '_'))
  const Header = _.get(Headers, edition.replace('-', '_'))

  const [offCanvasOpened, setOffCanvasOpened] = useState(false)

  const onBodyClick = (e) => {
    if (offCanvasOpened) {
      e.preventDefault()
      e.stopPropagation()
      setOffCanvasOpened(false)
    }
  }

  useEffect(() => {
    if (offCanvasOpened) {
      window.setTimeout(() => {
        document.body.classList.add('offcanvas-opened')
      }, 500)
    } else {
      document.body.classList.remove('offcanvas-opened')
    }
  })

  return (
    <SimpleReactLightbox>
      <div id="offcanvas" className={offCanvasOpened ? 'offcanvas-active' : ''}>
        <h3>Content</h3>
        <AsideMenu location={location} />
        <Link to="/team/" className="btn btn-outline-light">
          Need Help ?
        </Link>
      </div>
      <div id="app" onClick={onBodyClick}>
        <a
          className={
            'nav-toggle js-nav-toggle ' +
            (offCanvasOpened ? 'offcanvas-active' : '')
          }
          href="#"
          onClick={() => setOffCanvasOpened(!offCanvasOpened)}
        >
          <i></i>
        </a>
        <Header edition={edition} location={location} />
        <main className="main">
          <aside className="sidebar">
            <Sticky stickyStyle={{ marginTop: '150px' }} topOffset={-200}>
              <h3>Content</h3>
              <AsideMenu location={location} />
            </Sticky>
          </aside>
          <section className="content">
            {children}
            <footer className="copyright">
              © Compagnie des Montres Longines Francillon SA, 2021
            </footer>
          </section>
        </main>
      </div>
    </SimpleReactLightbox>
  )
}

Layout.defaultProps = defaultProps
export default Layout
