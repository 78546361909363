import React from 'react'
import { Link } from 'gatsby'
import { currentEdition, EditionDropdown } from '../global'

interface Props {
  children?: React.ReactNode
  edition: string
}

const Nav: React.FC<Props> = ({ children, edition }: Props) => (
  <header>
    <nav className="navbar">
      <Link to="/" className="navbar-brand d-flex">
        <img
          src={'/img/longines-logo.svg'}
          alt="Longines E-Commerce Newsletter"
          className="navbar-logo"
        />
        <div className="navbar-title">
          <span>Longines E-Commerce</span> <span>Newsletter #01/2021</span>
        </div>
      </Link>
      <div className="d-none d-lg-flex">
        {currentEdition.replace('_', '-') !== edition && (
          <Link to="/" className="nav-link">
            Back to homepage
          </Link>
        )}
        <EditionDropdown />
        <Link to="/team/" className="btn btn-primary need-help-btn">
          Need Help ?
        </Link>
      </div>
    </nav>
  </header>
)

export default Nav
