import React from 'react'
import AppLink from '../../components/link/link'

const urlPrefix = '/02-2021'

interface Props {
  children?: React.ReactNode
  location: Location
}

const Nav: React.FC<Props> = ({ children, location }: Props) => (
  <ol className="nav sidenav">
    <li>
      <AppLink location={location} to={`${urlPrefix}/opening-of-a-new-e-store/`}>
        1. Opening of new e-stores
      </AppLink>
    </li>
    <li>
      <AppLink location={location} to={`${urlPrefix}/straps-sales/`}>
        2. Straps sales on our corporate e-stores
      </AppLink>
    </li>
    <li>
      <AppLink location={location} to={`${urlPrefix}/user-guide-page/`}>
        3. User guide page : Upgrade
      </AppLink>
    </li>
    <li>
      <AppLink location={location} to={`${urlPrefix}/new-our-collection-page/`}>
        4. New "Our collections" page
      </AppLink>
    </li>
    <li>
      <AppLink location={location} to={`${urlPrefix}/wiki-website/`}>
        5. Wiki Website
      </AppLink>
    </li>
    <li>
      <AppLink location={location} to={`${urlPrefix}/collectors-corner/`}>
        6. Collector's corner
      </AppLink>
    </li>
    <li>
      <AppLink
        location={location}
        to={`${urlPrefix}/user-experience-ux-improvements/`}
      >
        7. User Experience (UX) improvements
      </AppLink>
    </li>
    <li>
      <AppLink
        location={location}
        to={`${urlPrefix}/addition-of-new-languages/`}
      >
        8. Addition of new languages
      </AppLink>
    </li>
    <li>
      <AppLink location={location} to={`${urlPrefix}/preorder/`}>
        9. Preorder
      </AppLink>
    </li>
    <li>
      <AppLink
        location={location}
        to={`${urlPrefix}/online-exclusive-products/`}
      >
        10. Online Exclusive Products
      </AppLink>
    </li>
    <li>
      <AppLink
        location={location}
        to={`${urlPrefix}/new-markets-open-click-and-reserve/`}
      >
        11. New Markets will open the Click & Reserve feature
      </AppLink>
    </li>
    <li>
      <AppLink location={location} to={`${urlPrefix}/global-site-performance/`}>
        12. Global Site Performance
      </AppLink>
    </li>
  </ol>
)

export default Nav
