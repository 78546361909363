import { Link } from 'gatsby'
import React from 'react'

interface Props {
  to: string
  className?: string
  children?: React.ReactNode
  location: Location
}
const defaultProps = {
  className: '',
}

const removeTrailingSlash = (url: string) =>
  url[url.length - 1] == '/' ? url.substr(0, url.length - 1) : url

const areSameUrls = (url1: string, url2: string) =>
  removeTrailingSlash(url1) === removeTrailingSlash(url2)

const AppLink: React.FC<Props> = ({
  to,
  className,
  location,
  children,
}: Props) => {
  if (location) {
    className = areSameUrls(location.pathname, to)
      ? `${className} active`
      : className
  }

  return (
    <Link to={to} className={className}>
      {children}
    </Link>
  )
}
AppLink.defaultProps = defaultProps
export default AppLink
