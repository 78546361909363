import React from 'react'
import Link from '../components/link/link'
// import edition component
import Header_01_2021 from './01-2021/Header'
import AsideMenu_01_2021 from './01-2021/AsideMenu'
import Header_02_2021 from './02-2021/Header'
import AsideMenu_02_2021 from './02-2021/AsideMenu'

const currentEdition = '02-2021'

// please use _ instead of - for keys
const Headers = {
  '01_2021': Header_01_2021,
  '02_2021': Header_02_2021,
}

// please use _ instead of - for keys
const AsideMenus = {
  '01_2021': AsideMenu_01_2021,
  '02_2021': AsideMenu_02_2021,
}

const EditionDropdown = () => (
  <div className="dropdown mr-2">
    <button
      className="btn btn-outline-dark dropdown-toggle"
      type="button"
      id="dropdownMenuButton"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      Archives
    </button>
    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
      <Link className="dropdown-item" to="/02-2021/">
        Edition #02/2021
      </Link>
      <Link className="dropdown-item" to="/01-2021/">
        Edition #01/2021
      </Link>
    </div>
  </div>
)

export { currentEdition, Headers, AsideMenus, EditionDropdown }
