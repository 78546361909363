import React from 'react'
import AppLink from '../../components/link/link'

const urlPrefix = '/01-2021'

interface Props {
  children?: React.ReactNode
  location: Location
}

const Nav: React.FC<Props> = ({ children, location }: Props) => (
  <ol className="nav sidenav">
    <li>
      <AppLink location={location} to={`${urlPrefix}/editorial/`}>
        1. Editorial
      </AppLink>
    </li>
    <li>
      <AppLink location={location} to={`${urlPrefix}/new-header-menu/`}>
        2. New header menu
      </AppLink>
    </li>
    <li>
      <AppLink location={location} to={`${urlPrefix}/new-features/`}>
        3. Collection & Product new Features
      </AppLink>
    </li>
    <li>
      <AppLink location={location} to={`${urlPrefix}/new-domains-opening/`}>
        4. New e-commerce and domains opening
      </AppLink>
    </li>
    <li>
      <AppLink location={location} to={`${urlPrefix}/click-and-reserve/`}>
        5. Click & reserve
      </AppLink>
    </li>
    <li>
      <AppLink location={location} to={`${urlPrefix}/online-live-appointment/`}>
        6. Online live appointment
      </AppLink>
    </li>
  </ol>
)

export default Nav
